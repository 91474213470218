angular.module('app').value('API_CONF', {
  base_url: '/rest',
  server: '/api',
  serverPassInsurance: 'http://pltf-umapass-int.ad.rgigroup.com',
  linkPassInsurance: 'http://pltf-umapass-int.ad.rgigroup.com/uma/multisession/ServletLogin',
  serverBundle: 'http://elastik-sale.aws.rgigroup.com:32600/',
});

angular.module('app').config(['$sceDelegateProvider', function ($sceDelegateProvider) {
    $sceDelegateProvider.resourceUrlWhitelist([
       'self',
       'http://pltf-umapassportal-int.ad.rgigroup.com/api/rest/**',
       'https://web1.unimaticaspa.it/**',
       'https://query.yahooapis.com/**',
        'http://elastik-sale.aws.rgigroup.com:32100/**',
        'http://elastik-sale.aws.rgigroup.com:32600/**',
    ]);
}]);